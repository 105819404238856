@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.CardRail {
  @include breakpoints.bpk-breakpoint-mobile {
    > div
      > [class*='BpkMobileScrollContainer_bpk-mobile-scroll-container']::before,
    > div
      > [class*='BpkMobileScrollContainer_bpk-mobile-scroll-container']::after {
      display: none;
    }
  }

  // make room for the scrolling buttons of DesktopScrollContainer
  .header {
    @include breakpoints.bpk-breakpoint-above-mobile {
      margin-inline-end: 104 * tokens.$bpk-one-pixel-rem;
    }
  }

  .mobile_scroll_container_hider {
    @include breakpoints.bpk-breakpoint-above-mobile {
      display: none;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      margin-inline: tokens.bpk-spacing-base() * -1;
    }
  }

  .mobile_scroll_container_body {
    display: flex;
    padding: tokens.bpk-spacing-lg() 0 tokens.bpk-spacing-lg() 0;
    gap: tokens.bpk-spacing-base();

    @include breakpoints.bpk-breakpoint-mobile {
      padding: tokens.bpk-spacing-base() 0;
      padding-inline: tokens.bpk-spacing-base();
    }
  }

  [class*='BpkMobileScrollContainer_bpk-mobile-scroll-container__inner'] {
    display: flex;
  }
}
