@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.CardRail {
  &__card {
    @include breakpoints.bpk-breakpoint-mobile {
      width: tokens.$bpk-one-pixel-rem * 312;
      margin: 0;
    }
  }
}

.SelectableChip {
  width: 100%;
  margin-bottom: 12 * tokens.$bpk-one-pixel-rem;

  &__item {
    display: inline-block;
    margin-inline-end: tokens.bpk-spacing-md();
  }
}

.CardRailWithThemeCard {
  &__header {
    margin-bottom: 12 * tokens.$bpk-one-pixel-rem;
  }

  .card {
    // override themCard hardcode width
    [class*='ThemeCard_ThemeCard'] {
      width: auto;
      min-width: auto;
      margin: 0;

      @include breakpoints.bpk-breakpoint-mobile {
        width: auto;
        min-width: auto;
      }
    }

    [class*='ThemeCard_ThemeCard__badges'] {
      margin-top: tokens.bpk-spacing-md();
    }

    div[class*='ThemeCard_ThemeCard__themeCardImage'] {
      width: auto;
      min-width: auto;
    }

    div[class*='ThemeCard_ThemeCard__themeCardImage'] div {
      width: auto;
      min-width: auto;
    }
  }
}
