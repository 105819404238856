@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.CardStack {
  &__content {
    display: flex;
    margin-top: tokens.bpk-spacing-lg();
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &__callToAction {
    margin-top: tokens.bpk-spacing-lg();
    text-align: center;
  }

  &__disclaimer {
    margin-top: tokens.bpk-spacing-lg();
  }
}
