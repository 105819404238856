@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.CardStack {
  display: flex;
  flex-flow: row wrap;
  margin-inline-end: -1 * tokens.bpk-spacing-lg();

  @include breakpoints.bpk-breakpoint-mobile {
    margin-inline-end: 0;
  }

  &__card {
    width: 33.3%;
    margin-bottom: tokens.bpk-spacing-lg();
    padding-inline-end: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-small-tablet {
      width: 50%;
      padding-inline-end: tokens.bpk-spacing-lg();
    }

    @include breakpoints.bpk-breakpoint-mobile {
      width: 100%;
      padding-inline-end: 0;
    }
  }

  &__hide {
    display: none;
  }
}

.SelectableChip {
  width: 100%;
  margin-bottom: tokens.bpk-spacing-lg();

  @include breakpoints.bpk-breakpoint-mobile {
    margin-bottom: tokens.bpk-spacing-md();
  }

  &__item {
    display: inline-block;
    margin-inline-end: tokens.bpk-spacing-md();
  }
}

.CardStackWithThemeCard {
  .card {
    // override themCard hardcode width
    [class*='ThemeCard_ThemeCard'] {
      width: auto;
      min-width: auto;
      margin: 0;

      @include breakpoints.bpk-breakpoint-mobile {
        width: auto;
        min-width: auto;
      }
    }

    [class*='ThemeCard_ThemeCard__badges'] {
      margin-top: tokens.bpk-spacing-md();
    }

    div[class*='ThemeCard_ThemeCard__themeCardImage'] {
      width: auto;
      min-width: auto;
    }

    div[class*='ThemeCard_ThemeCard__themeCardImage'] div {
      width: auto;
      min-width: auto;
    }
  }

  div[class*='CardStack_CardStack__content'] {
    display: block;
    flex: none;
  }
}
