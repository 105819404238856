@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.ThemeCardWrapper {
  position: relative;

  .ThemeCard {
    min-width: tokens.$bpk-one-pixel-rem * 315.5;

    /*
      Need more space in the bottom for the box-shadow of cards.bpk-card to show.
      Without extra space, the bottom shadow was cropped because
      overflow:hidden is necessary for the container (DesktopScrollContainer)
    */
    $card-margin: tokens.bpk-spacing-base() * 0.75;
    $card-margin-bottom: tokens.bpk-spacing-base();

    margin: $card-margin $card-margin $card-margin-bottom $card-margin;
    flex-grow: 1;
    overflow: auto;

    @include breakpoints.bpk-breakpoint-mobile {
      width: tokens.$bpk-one-pixel-rem * 312;
      min-width: unset;
      margin: 0;
    }

    &__topBadges {
      position: absolute;
      top: tokens.bpk-spacing-base();
      right: tokens.bpk-spacing-base();
    }

    &__themeCardImage {
      min-width: tokens.$bpk-one-pixel-rem * 315.5;
      border-radius: tokens.$bpk-border-radius-md tokens.$bpk-border-radius-md 0
        0;
      overflow: hidden;

      @include breakpoints.bpk-breakpoint-mobile {
        width: tokens.$bpk-one-pixel-rem * 312;
        min-width: unset;
      }

      div {
        min-width: tokens.$bpk-one-pixel-rem * 315.5;

        @include breakpoints.bpk-breakpoint-mobile {
          width: tokens.$bpk-one-pixel-rem * 312;
          min-width: unset;
        }
      }

      img {
        object-fit: cover;
      }
    }

    &__main {
      margin-top: -1 * tokens.$bpk-one-pixel-rem;
      padding: tokens.bpk-spacing-base();
      border-top: tokens.$bpk-input-border;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__leadingText {
      color: tokens.$bpk-text-secondary-day;
    }

    &__badges {
      display: flex;
      margin-top: tokens.bpk-spacing-md();
      margin-right: tokens.bpk-spacing-md();
      flex-flow: row wrap;
      gap: tokens.bpk-spacing-md();

      .badgeIcon {
        display: inline-flex;
        margin-top: tokens.bpk-spacing-sm();
        margin-inline-end: tokens.bpk-spacing-sm();
      }
    }

    &__leftAligned {
      margin-right: tokens.bpk-spacing-md();

      @include breakpoints.bpk-breakpoint-mobile {
        overflow: hidden;
      }

      h3 {
        @include breakpoints.bpk-breakpoint-mobile {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &__rightAligned {
      text-align: right;
    }

    &__textBody {
      padding-top: tokens.bpk-spacing-md();
      white-space: normal;
    }
  }

  .themeCardImageCenterWrapper {
    margin: 18 * tokens.$bpk-one-pixel-rem 44 * tokens.$bpk-one-pixel-rem;

    .themeCardImageCenter {
      img {
        margin: auto;
      }
    }
  }
}
