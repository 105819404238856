@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.ViewAll {
  text-align: center;

  &__button {
    color: tokens.$bpk-core-accent-day;
  }

  &__buttonArrow {
    display: inline-block;
    margin-left: tokens.bpk-spacing-md();

    @include utils.bpk-rtl {
      margin-right: tokens.bpk-spacing-md();
      margin-left: 0;
    }
  }
}

.CardDeals {
  div[class*='ThemeCard_themeCardImageCenterWrapper'] {
    width: 59%;
    margin-right: auto;
    margin-left: auto;
  }
}
